'use client'
import { useRouter } from 'next/navigation'
import useTranslation from 'next-translate/useTranslation'
import React, { useState } from 'react'

import { useUser } from '@/hooks/useUser'

import NextImage from '@/components/NextImage'

import ImageModal from './ImageModal'

import { ChatChannelInfo } from '@/types'

function shortName(brand) {
  const list = brand.split(' ')

  return list[0].charAt(0) + list[list.length - 1].charAt(0)
}

function ForumItem({ forum }: { forum: ChatChannelInfo }) {
  const { t, lang } = useTranslation()
  const router = useRouter()

  const { user } = useUser()

  const [showDownlaodApp, setShowDownlaodApp] = useState(false)

  const handleClick = () => {
    // if (user) {

    // } else {
    //   router.push(`/${lang}/auth/login`)
    // }

    setShowDownlaodApp(true)
  }

  const tags = forum.tags || []
  const locationCityName = forum.locationCityName || ''
  const showHangoutBanner = tags.includes('gathering')
  const isGlobal = tags.includes('global')

  let cityName = locationCityName

  if (isGlobal) {
    cityName = t(`Global`)
  }


  const communityTitle = forum.communityTitle
  const avatarText = shortName(forum.name)

  return (
    <>
      {showDownlaodApp && <ImageModal type="download-app-pop-up" handleCloseModal={() => setShowDownlaodApp(false)} />}
      <div className="w-full min-w-[320px] md:mr-3 md:w-[320px]">
        <button
          type="button"
          onClick={handleClick}
          className="border-text-300 relative z-10 mb-3 flex w-full flex-1 flex-col overflow-hidden rounded-[20px] border-[0.7px] bg-white px-5 py-4 shadow-md">
          <div className="flex w-full flex-row">
            {forum.photoUrl ? (
              <div className="h-[54px] w-[54px] self-center">
                <NextImage
                  className="h-[54px] w-[54px] rounded-full object-cover"
                  alt={forum.name}
                  src={forum.photoUrl}
                  width={54}
                  height={54}
                  // style={{ width: '72px', height: '72px', objectFit: 'fill' }}
                  // unoptimized
                />
              </div>
            ) : (
              <div className="self-center">
                <div className="relative flex h-[54px] w-[54px] items-center justify-center   rounded-full border border-[#fff] bg-[#E0EDEF]">
                  <span className="font-medium text-[#2A888C]">{(avatarText || '').toUpperCase()}</span>
                </div>
              </div>
            )}

            <div className="ml-3 w-full">
              <div className="w-full">
                {cityName ? (
                  <p className="text-primary-800 text-left text-[13px] font-medium">{cityName}</p>
                ) : (
                  <p className="text-left text-[13px] font-medium">{'‎ '}</p>
                )}
                {/* {communityTitle ? (
                  <p className="text-[#2A888C] text-left text-[13px] font-medium">{communityTitle}</p>
                ) : (
                  null
                )} */}
                <p className="line-clamp-2 text-left text-lg font-normal min-h-[56px]">{forum.name}</p>
              </div>
              <div className="flex w-full flex-row items-center justify-between">
                <p className="text-text-600 text-[13px]">
                  {forum.participantsCount} {t(`participantes`)}
                </p>
                <p className="text-secondary-400 text-right text-base font-medium">{t(`JOIN`)}</p>
              </div>
            </div>
          </div>
          {/* <div className="ml-3 flex w-full flex-row">
            <div className="w-[54px]"></div>

          </div> */}
          {showHangoutBanner && (
            <div className="absolute right-0 top-0 h-16 w-16">
              <div className="bg-tertiaryYellow-400 absolute right-[-45px] top-[14px] w-[140px] rotate-45 transform py-1 text-center text-xs text-white">
                {t(`Quedadas`)}
              </div>
            </div>
          )}
        </button>
      </div>
    </>
  )
}

export default ForumItem
